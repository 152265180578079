.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Table-toolbar{
  display: flex;
  padding: 4px 4px 0px;
  justify-content: space-between;
  border-bottom: 3px solid #111111;
  padding-bottom: 10px;
}

.Table-icon {
  z-index: 10;
}
.Table-icon-bg {
  background-color: #111111;
  width: 110px;
  height: 110px;
  border-radius: 5px;
  position: absolute;
  margin: -35px -19px -11px -35px;
}

.offline-text {
  z-index: 9999999;
  position: fixed;
  top: 0;
  text-align: center;
  width: 100%;
  background: black;
  color: red;
  padding: 5px;
}

.wizard-nav {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background: #f6f6f6;
  padding: 16px;
  border-top: 1px solid lightgray;
  box-shadow: 0px -2px 5px 3px #00000008;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
